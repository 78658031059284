
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Modal,
  ModalBody,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import { defaultTitle } from "../../constants/layout";
import { Button } from "../../components/Common/Button";

import API from "../../services/api";
import { aAlert, getUrlParam, imageToBase64, removeUrlParam } from "../../helpers/general_helper";
import { Loading } from "../../components/Common/Loading";
import { getLoggedinUser, getUserAvatar, setLoggedinUser } from "../../helpers/api_helper";
import imageCompression from "browser-image-compression";

const UserProfile = () => {
  document.title = `Meu Perfil | ${defaultTitle}`;

  const [is_loading, setis_loading] = useState(true);
  const [is_loading_post1, setis_loading_post1] = useState(false);
  const [is_loading_post2, setis_loading_post2] = useState(false);
  
  const [modal_delete, setmodal_delete] = useState(false);
  function tog_delete() {
    setmodal_delete(!modal_delete);
  }
  
  const [modal_edit, setmodal_edit] = useState(false);
  function tog_edit() {
    setmodal_edit(!modal_edit);
    if(modal_edit){
      validationAvatar.setValues({ user_avatar: undefined });
    }
  }

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [userAvatar64, setUserAvatar64] = useState(null);
  const [canRemoveAvatar, setCanRemoveAvatar] = useState(false);


  useEffect(() => {
    const av = getUserAvatar();
    setUserAvatar(av);
    if(av.startsWith("http")){
      setCanRemoveAvatar(true);
    }

    API.get("users/me")
    .then(({ data }) => {
      setUserName(data.name);
      setUserEmail(data.username);
    })
    .catch((error) => {
      aAlert(error?.response?.data?.message ?? error.message, "error");
    })
    .finally(() => {
      setis_loading(false);
    });
    showSuccessMessages();
  }, []);

  // Form validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_name: userName,
    },
    validationSchema: Yup.object({
      user_name: Yup.string().required("Por favor, informe o seu nome."),
    }),
    onSubmit: (values) => {
      changeMyName({
        name: values.user_name,
        avatar: null,
      });
    },
  });

  // Form validation
  const validationPwd = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_password: "",
      user_password_confirm: "",
    },
    validationSchema: Yup.object({
      user_password: Yup.string()
        .min(8, "A senha deve ter no mínimo 8 caracteres")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "A senha deve conter pelo menos uma letra, um número e um caractere especial"
        )
        .required("Por favor, informe a sua senha."),
      user_password_confirm: Yup.string()
        .oneOf([Yup.ref('user_password'), null], 'As senhas devem ser iguais')
        .required("Por favor, confirme a sua senha."),
    }),
    onSubmit: (values) => {
      changeMyPassword({ password: values.user_password })
    },
  });

  // Form validation
  const validationAvatar = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_avatar: "",
    },
    validationSchema: Yup.object({
      user_avatar: Yup.mixed().test('isFile', 'Por favor, selecione uma imagem.', value => {
        if(value === undefined || value === "" || typeof value !== "string"){
          return false;
        }
        return value.toLowerCase().includes(".png") || value.toLowerCase().includes(".jpg") || value.toLowerCase().includes(".jpeg");
      }),
    }),
    onSubmit: (values) => {
      tog_edit();
      setis_loading(true);
      changeMyName({
        name: userName,
        avatar: values.user_avatar !== '' && userAvatar64 !== null ? userAvatar64 : null,
      });
    },
  });

  const changeMyName = (values) => {
    setis_loading_post1(true);
    API.put("users/me", values)
    .then(({ data }) => {
      setis_loading(true);
      const user = getLoggedinUser();
      user.name = values.name;
      user.avatar = data.avatar;
      setLoggedinUser(user);
      window.location = '/userprofile?success=1';
    })
    .catch((error) => {
      setis_loading_post1(false);
      aAlert(error?.response?.data?.message ?? error.message, "error");
    });
  }
  
  const changeMyPassword = (values) => {
    setis_loading_post2(true);
    API.post("users/me", values)
    .then(({ data }) => {
      setis_loading(true);
      window.location = '/userprofile?success=2';
    })
    .catch((error) => {
      setis_loading_post2(false);
      aAlert(error?.response?.data?.message ?? error.message, "error");
    });
  }

  const showSuccessMessages = () => {
    switch(getUrlParam('success')){
        case "1":
          aAlert("Dados alterados com sucesso!", "success");
        break;
        case "2":
          aAlert("Senha alterada com sucesso!", "success");
        break;
        default: break;
    }
    removeUrlParam("success");
  }

  const handleFileInputChange = async (event) => {
    setis_loading(true);
    validationAvatar.handleChange(event);
    const file = event.target.files[0];
    if (file) {
      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 127,
          useWebWorker: true
        });
        imageToBase64(compressedFile)
        .then(base64String => {
          setUserAvatar64(base64String);
        })
        .catch(error => {
          aAlert(`Erro ao converter imagem em base64: ${error}`, "error");
        })
        .finally(() => {
          setis_loading(false);
        });
      } catch (error) {
        aAlert(`Erro ao redimensionar e converter a imagem: ${error}`, "error");
      }
    } else {
      setis_loading(false);
      setUserAvatar64(null);
    }
  }

  const deleteAvatar = () => {
    setmodal_delete(false);
    changeMyName({
      name: userName,
      avatar: 'nda',
    });
  }

  return (
    <React.Fragment>
        <Loading isLoading={is_loading} />
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Metrikey" breadcrumbItem="Meu Perfil" />

            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <div className="text-center">
                            <img
                              src={userAvatar}
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail mb-3"
                              style={{
                                objectFit: 'cover'
                              }}
                            />
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="edit mx-2">
                                  <button type="button" className="btn btn-sm btn-info remove-item-btn" onClick={() => tog_edit()}>
                                    <i className="fa fa-edit"></i>
                                  </button>
                              </div>
                              {canRemoveAvatar &&
                                <div className="remove mx-2">
                                    <button type="button" className="btn btn-sm btn-danger remove-item-btn" onClick={() => tog_delete()}>
                                      <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                              }
                            </div>
                          </div>
                        </Col>
                        <Col md="5">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">Nome</Label>
                            <Input
                              name="user_name"
                              placeholder=""
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.user_name || ""}
                              invalid={
                                validation.touched.user_name &&
                                validation.errors.user_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.user_name &&
                            validation.errors.user_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.user_name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="5">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">E-mail</Label>
                            <div>{ userEmail }</div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col md="12">
                  <Button
                    style={{ width: "100%" }}
                    text="Salvar Alterações"
                    isLoading={is_loading_post1}
                  />
                </Col>
              </Row>
            </Form>

            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                validationPwd.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">Senha</Label>
                              <Input
                                name="user_password"
                                placeholder=""
                                type="password"
                                className="form-control"
                                id="validationCustom02"
                                onChange={validationPwd.handleChange}
                                onBlur={validationPwd.handleBlur}
                                value={validationPwd.values.user_password || ""}
                                invalid={
                                  validationPwd.touched.user_password &&
                                  validationPwd.errors.user_password
                                    ? true
                                    : false
                                }
                              />
                              {validationPwd.touched.user_password &&
                              validationPwd.errors.user_password ? (
                                <FormFeedback type="invalid">
                                  {validationPwd.errors.user_password}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom03">Confirmar Senha</Label>
                              <Input
                                name="user_password_confirm"
                                placeholder=""
                                type="password"
                                className="form-control"
                                id="validationCustom03"
                                onChange={validationPwd.handleChange}
                                onBlur={validationPwd.handleBlur}
                                value={validationPwd.values.user_password_confirm || ""}
                                invalid={
                                  validationPwd.touched.user_password_confirm &&
                                  validationPwd.errors.user_password_confirm
                                    ? true
                                    : false
                                }
                              />
                              {validationPwd.touched.user_password_confirm &&
                              validationPwd.errors.user_password_confirm ? (
                                <FormFeedback type="invalid">
                                  {validationPwd.errors.user_password_confirm}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col md="12">
                  <Button
                    style={{ width: "100%" }}
                    text="Alterar Minha Senha"
                    isLoading={is_loading_post2}
                    color="danger"
                  />
                </Col>
              </Row>
            </Form>
            
          </Container>
        </div>

        <Modal isOpen={modal_delete} toggle={() => { tog_delete(); }} className="modal fade zoomIn show" id="deleteRecordModal" centered >
            <div className="modal-header">
                <button type="button" onClick={() => setmodal_delete(false)} className="btn-close" aria-label="Close"> </button>
            </div>
            <ModalBody>
                <div className="mt-2 text-center">
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Tem Certeza ?</h4>
                        <p className="text-muted mx-4 mb-0">Realmente deseja remover a sua foto ?</p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" className="btn w-sm btn-danger " id="delete-record" onClick={() => deleteAvatar()}>Sim, Remover Agora!</button>
                    <button type="button" className="btn w-sm btn-light" onClick={() => setmodal_delete(false)}>Cancelar</button>
                </div>
            </ModalBody>
        </Modal>

        <Modal isOpen={modal_edit} toggle={() => { tog_edit(); }} className="modal fade zoomIn show" id="editRecordModal" centered >
            <div className="modal-header">
                <button type="button" onClick={() => tog_edit()} className="btn-close" aria-label="Close"> </button>
            </div>
            <ModalBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => {
                  e.preventDefault();
                  validationAvatar.handleSubmit();
                  return false;
                }}
              >
                <div className="mt-2 text-center">
                    <div className="pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Alterar Minha Foto de Perfil</h4>
                      <div className="mt-4">
                        <Input
                          name="user_avatar"
                          placeholder=""
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          className="form-control"
                          id="validationCustom08"
                          onChange={handleFileInputChange}
                          onBlur={validationAvatar.handleBlur}
                          value={validationAvatar.values.user_avatar || ""}
                          invalid={
                            validationAvatar.touched.user_avatar &&
                            validationAvatar.errors.user_avatar
                              ? true
                              : false
                          }
                        />
                        {validationAvatar.touched.user_avatar &&
                          validationAvatar.errors.user_avatar ? (
                            <FormFeedback type="invalid">
                              {validationAvatar.errors.user_avatar}
                            </FormFeedback>
                          ) : null}
                      </div>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="submit" className="btn w-sm btn-info " id="edit-record">Alterar Avatar!</button>
                    <button type="button" className="btn w-sm btn-light" onClick={() => tog_edit()}>Cancelar</button>
                </div>
              </Form>
            </ModalBody>
        </Modal>

    </React.Fragment>
  );
};

export default withRouter(UserProfile);
