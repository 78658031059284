import React, { useEffect } from "react";
import Routes from "./Routes/index";

// Import Scss
import './assets/scss/theme.scss';

// Fake Backend 
import { getLoggedinUser } from "./helpers/api_helper";
import { changeLayoutMode } from "./store/actions";
import { useDispatch } from "react-redux";


function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    const user = getLoggedinUser();
    if(user){
      dispatch(changeLayoutMode(user.layout_mode));
    }
  }, []);

  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
